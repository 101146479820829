import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChartDataset, ChartType, ChartOptions, Chart } from 'chart.js';
import { min } from 'mathjs';

@Component({
  selector: 'app-liss-curve',
  templateUrl: './liss-curve.component.html',
  styleUrls: ['./liss-curve.component.css']
})
export class LissCurveComponent implements OnInit {
  chartContext: CanvasRenderingContext2D;
  chart: Chart;
  points: {x: number, y: number}[] = [{x: 0, y: 0}];
  public precision: number = 10; // divider
  maxIterations: number = 10000;
  public showChart = true;
  public isAnimating = false;
  animationSpeed: number = 1;
  animationNo: number = 1;
  public omegaX: number = 2.5;
  public omegaY: number = 1;
  public phaseX: number = 5;
  public phaseY: number = 0;



  constructor() { }

  ngOnInit(): void {
    this.findCanvas();
    this.createChart();
    this.updateChart();
    console.log(this.points);
  }

  calculatePoints(): void {
    let newPoints: {x: number, y: number}[] = [];
    let iteration = 0;
    for (iteration; iteration < this.precision; iteration++) {
      newPoints.push({x: this.functionX(iteration), y: this.functionY(iteration)});
    }
    while (!this.isLooping(newPoints[0], newPoints[newPoints.length-1], iteration)) {
      newPoints.push({x: this.functionX(iteration), y: this.functionY(iteration)});
      iteration += 1;
    }
    // make sure to close the loop
    newPoints.push({x: this.functionX(iteration), y: this.functionY(iteration)});
    this.points = newPoints;
  }

  functionX(interation: number): number {
    return Math.sin(this.omegaX*interation/this.precision + this.phaseX);
  }

  functionY(iteration: number): number {
    return Math.cos(this.omegaY*iteration/this.precision + this.phaseY);
  }

  isLooping(firstPoint: {x: number, y: number}, lastPoint: {x: number, y: number}, iterations: number): boolean {
    let distance = Math.sqrt(Math.pow(lastPoint.x-firstPoint.x, 2)+Math.pow(lastPoint.y-firstPoint.y, 2))
    if (distance < 1/this.precision || iterations > this.maxIterations) {
      return true;
    }
    return false;
  }

  findCanvas(): void {
    let element = <HTMLCanvasElement> document.getElementById('curveCanvas');
    this.chartContext = element.getContext('2d');
  }

  async updateChart() {
    console.log("Updating chart");
    this.calculatePoints();

    this.animationNo += 1;
    let myAnimationNo = this.animationNo;

    if (this.isAnimating) {
      let animationDelay = 250/this.precision * this.animationSpeed;
      let arrayLen = this.points.length;
      for (let i = 1; i < arrayLen; i++) {
        if (!this.isAnimating) break;
        if (this.animationNo > myAnimationNo) break;
        this.chart.data.datasets[0].data = this.points.slice(0, i);
        this.chart.update();
        // sleep
        console.log("Animation cycle"+i);
        await new Promise(resolve => setTimeout(resolve, animationDelay));
      }
    } else {
      this.chart.data.datasets[0].data = this.points;
      this.chart.update();
      // this.scatterChartData[0].data = this.points;
    }
  }

  findRange(): {min: number, max: number} {
    let currMin = 0;
    let currMax = 0;
    for (let point of this.points) {
      if (point.x < currMin || point.y < currMin) {
        currMin = Math.min(point.x, point.y);
      }
      if (point.x > currMax || point.y > currMax) {
        currMin = Math.max(point.x, point.y);
      }
    }
    return {min: currMin, max: currMax};
  }

  createChart() {
    this.chart = new Chart(this.chartContext, {
      type: 'scatter',
      data: {
          datasets: [
            {
              data: this.points,
              pointRadius: 0,
              pointHoverRadius: 0,
              tension: 0,
              showLine: true,
              borderColor: '#5a1bee',
              borderWidth: 2,
            },
          ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        animation: false,
        scales: {
          yAxis: {
            min: -1,
            max: 1
          },
          xAxis: {
            min: -1,
            max: 1
          }
        },
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          }
        }
      }
  });
  }

}
