<div class="row col-11 ms-2">
  <div class="bg-light rounded-3 py-2">
    <span class="fw-bold">Lissajous curve</span><span> - a pattern produced by the intersection of two sinusoidal curves the axes of which are at right angles to each other. </span>
    <p class="mb-0">You can modify the function arguments on the left and immiditely observe the generated curve, or simulate a physical pendulum by checking the "Animate" check-box.
  </p>
  </div>
</div>
<br>
<div class="row">
  <div class="col-3 p-5 me-2 bg-light rounded-3 flex-column d-flex">
      <h3 class="text-center mb-2">Settings</h3>
      <h4 class="text-center fs-6 text-secondary mb-0">x = <i>sin</i>(ω<sub>x</sub>t+ϕ<sub>x</sub>)</h4>
      <h4 class="text-center fs-6 text-secondary mb-4">y = <i>cos</i>(ω<sub>y</sub>t+ϕ<sub>y</sub>)</h4>

      <div class="d-flex justify-content-between">
          <div class="col-9 mt-2">ω<sub>x</sub></div>
          <div class="col-3"><input type="email" class="form-control text-end" [(ngModel)]="omegaX" (change)="updateChart()"></div>
      </div>
      <input type="range" class="form-range" min="0.1" max="5" step="0.1" [(ngModel)]="omegaX" (change)="updateChart()">

      <div class="d-flex justify-content-between">
          <div class="col-9 mt-2">ϕ<sub>x</sub></div>
          <div class="col-3"><input type="email" class="form-control text-end" [(ngModel)]="phaseX" (change)="updateChart()"></div>
      </div>
      <input type="range" class="form-range" min="0.1" max="5" step="0.1" [(ngModel)]="phaseX" (change)="updateChart()">

      <div class="d-flex justify-content-between">
          <div class="col-9 mt-2">ω<sub>y</sub></div>
          <div class="col-3"><input type="email" class="form-control text-end" [(ngModel)]="omegaY" (change)="updateChart()"></div>
      </div>
      <input type="range" class="form-range" min="0.1" max="5" step="0.1" [(ngModel)]="omegaY" (change)="updateChart()">

      <div class="d-flex justify-content-between">
          <div class="col-9 mt-2">ϕ<sub>y</sub></div>
          <div class="col-3"><input type="email" class="form-control text-end" [(ngModel)]="phaseY" (change)="updateChart()"></div>
      </div>
      <input type="range" class="form-range" min="0.1" max="5" step="0.1" [(ngModel)]="phaseY" (change)="updateChart()">

      <div class="d-flex justify-content-between">
          <div class="col-9 mt-2">Precision</div>
          <div class="col-3"><input type="email" class="form-control text-end" [(ngModel)]="precision" (change)="updateChart()"></div>
      </div>
      <input type="range" class="form-range" min="2" max="50" step="1" [(ngModel)]="precision" (change)="updateChart()">



      <div class="mb-3 form-check mt-auto">
          <input type="checkbox" class="form-check-input" [(ngModel)]="isAnimating" (change)="updateChart()">
          <label class="form-check-label mb-0">Animate!</label><br>
          <label class="form-check-label fs-6 fw-light text-secondary">I want my computer to burn.</label>
      </div>
  </div>
  <div class="col-8 d-flex justify-content-center p-3 ms-2 bg-light rounded-3" style="height: 45rem">
      <div class="p-2" style="width: 80%;">
          <!-- <canvas baseChart *ngIf="showChart"
                  [datasets]="scatterChartData"
                  [options]="scatterChartOptions"
                  [type]="scatterChartType">
          </canvas> -->
          <canvas id="curveCanvas"></canvas>
      </div>
  </div>
</div>
